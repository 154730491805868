import axios from "axios";


export const salesListsApi =
{
    /**
     * get all salesLists
     * @returns
     */
    async getAll() {
        const resp = await axios.get("forsales/sales_lists");
        return resp;
    },

    async getList_1(salesListID) {
        return await axios.get(`forsales/sales_lists/${salesListID}`);
    },

    async updateSaleslist(sales_lists_id, sales_list) {
        // const sales_lists_id = sales_list.id
        return await axios.patch(
            `forsales/sales_lists/${sales_lists_id}`,
            sales_list
        )
    },

    async getAllTemplates() {
        const resp = await axios.get("forsales/sales_templates");
        return resp;
    },

    /**
     * get all salesListsDetails
     * @returns
     */
    async getAllDetails() {
        const resp = await axios.get("forsales/sales_lists_details");
        return resp;
    },

    /**
     * get salesListsDetails filter by sales_list_id
     * @returns
     */
    async getAllDetailsFilter(salesListID) {
        const resp = await axios.get(`forsales/sales_lists_details/${salesListID}`);
        return resp;
    },

    async getDetails_1(sales_lists_detail_id) {
        const resp = await axios.get(`forsales/sales_lists_details_1/${sales_lists_detail_id}`);
        return resp;
    },

    async get_sales_template_1(sales_template_id) {
        console.log("sales_template_id:", sales_template_id)
        return await axios.get(`forsales/sales_templates/${sales_template_id}`);
    },

    /**
     * delete a salesLists
     * @returns
     */
    async deleteSalesList(salesListId) {
        const resp = await axios.delete(`forsales/sales_lists/${salesListId}`);
        return resp;
    },

    /**
     * delete a sales_template
     * @returns
     */
    async delete_sales_template(template_id) {
        return await axios.delete(`forsales/sales_templates/${template_id}`);
    },

    /**
     * delete a salesListsDetail
     * @returns
     */
    async deleteSalesListDetail(salesListDetailId, data) {
        return await axios.delete(
            `forsales/sales_lists_details/${salesListDetailId}`,
            { params: data }
        );
    },

    async createSaleslist(sales_list) {
        return await axios.post("forsales/sales_lists/new", sales_list);
    },

    async createSaleslist_detail(sales_list_details, locale) {
        const sales_list_id = sales_list_details.sales_list_id
        return await axios.post(
            `forsales/sales_lists/${sales_list_id}/sales_lists_details/new?locale=${locale}`,
            sales_list_details
        )
    },

    /**
   * saleslist_detailデータ新規登録（CSV）
   * @param {FormData} formData
   * @returns
   */
    async create_saleslist_detail_csv(formData) {
        return await axios.post("forsales/csv/sales_lists/sales_lists_details/new",formData)
    },

    async create_sales_template(sales_template) {
        return await axios.post("forsales/sales_templates/new", sales_template)
    },

    async update_sales_template(sales_template) {
        const sales_template_id = sales_template.id
        return await axios.patch(
            `forsales/sales_templates/${sales_template_id}`,
            sales_template)
    },

    async updateSaleslist_detail(sales_list_details) {
        const sales_lists_detail_id = sales_list_details.sales_lists_detail_id
        return await axios.patch(
            `forsales/sales_lists_details/${sales_lists_detail_id}`,
            sales_list_details
        )
    },

    async update_target_flag( detail_id, formData)
    {
        return await axios.patch(
            `forsales/change_target_flag?detail_id=${detail_id}`,
            formData
        )
    },
    // async update_sales_list_detail_target_flag(
    //     sales_lists_detail_id, target_flag, locale)
    // {
    //     return await axios.patch(
    //         `forsales/change_target_flag/${sales_lists_detail_id}?locale=${locale}&target_flag=${target_flag}`
    //     )
    // },

    async company_crawler(ws_id, formData) {
        const resp = await axios.post(
            `company-crawler?ws_id=${ws_id}`,
            formData,
            { headers: { accept: "application/json" } }
        );

        console.log("resp:", resp)
        return resp;
    },

    async send_mail(detail_id, formData) {
        const resp = await axios.post(
            `forsales/send-mail?detail_id=${detail_id}`,
            formData
            // { headers:{ accept: "application/json"}}
        );

        console.log("resp:", resp)
        return resp;
    },

    /**
     * check option access
     * @returns
     */
    async check_optionAccess() {
        const resp = await axios.get("forsales/option_access");
        return resp.data;
    },

    /**
     * forsalesの月次リスト
     * @returns
     */
    async getMothList() {
        const resp = await axios.get("forsales/month");
        return resp.data;
    },

    /**
     * forsalesの月次利用状況
     * @param {String} month
     * @returns
     */
    async getMothSummaries(month) {
        const resp = await axios.get(`forsales/month/summaries/${month}`);
        return resp.data;
    },

    /**
     * forsalesの月次利用金額
     * @param {String} month
     * @returns
     */
    async getTotalAmount(month) {
        const resp = await axios.get(`forsales/month/amount/${month}`);
        return resp.data;
    },

    /**
     * forsalesのSalesDetaiの最大ページ数
     * @param {number} sales_list_id
     * @returns
     */
    async getSalesDetailPage(sales_list_id) {
        const resp = await axios.get(`forsales/sales_lists_details/page/${sales_list_id}`);
        return resp.data;
    },

    /**
     * forsales：CSVヘッダー取得
     * @param {FormData} formData
     * @returns 
     */
    async getCsvHeader(formData) {
        const resp = await axios.post(
            "forsales/sales_lists_details/csv/header/",
            formData,
            { headers: { accept: "application/json" } }
        );
        return resp.data;
    },

    /**
     * forsales：メール内容再生成
     * @param {regenerationRequest} regenerationRequest
     * @returns
     */
    async regenerationSalesListsDetail(regenerationRequest) {
        return await axios.post("forsales/regeneration/sales_lists_details", regenerationRequest);
    },
};

